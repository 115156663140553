import React, { useState, useContext } from 'react';
import {
    Button, TextField,
    Dialog, DialogContent, FormControlLabel, Grid,
    AppBar, Toolbar, IconButton, Typography,
    Autocomplete, Checkbox, Divider
} from '@mui/material';
import { Send, DeleteForever, Close, Add, Cancel, Save, PriorityHigh } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { createFilterOptions } from '@mui/material/Autocomplete';
import DosageTimingInputs from '../components/DosageTimingInputs';
import { DateTime } from 'luxon';
import axios from '../CustomAxios';
import { UserContext } from '../context/UserContext';
import { toast } from 'react-toastify';

const filter = createFilterOptions({
    ignoreAccents: false
});

function TreatmentDialog(
    {
        currentTreatment, setCurrentTreatment, handleClose, patients, drugs,
        setSelectedRows, setSaveFirst, setSendOpen, setDeleteOpen, handleSave,
        open
    }
) {
    const [userContext, setUserContext] = useContext(UserContext);
    const [isPatientTreatmentUnique, setIsPatientTreatmentUnique] = useState(true);
    const isRequiredFieldInvalid = (field) => {
        if (field === '' || field === undefined || field === null)
            return true;
        return false;
    };

    const isTreatmentInvalid = (treatment) => {
        if (isRequiredFieldInvalid(treatment.patientId))
            return true;
        if (isRequiredFieldInvalid(treatment.treatmentLength))
            return true;
        return treatment.treatmentDosages?.some((dosage) => {
            if (isRequiredFieldInvalid(dosage.drugId))
                return true;
            if (!/^\d+(\.\d+)?$/.test(dosage.dosage))
                return true;
            if (dosage.weekdays.length === 0)
                return true;
            if (isRequiredFieldInvalid(dosage.timing))
                return true;
            return false;
        });
    };
    const modifyCurrentTreatment = (e, type) => {
        var tempTreatment = Object.assign({}, currentTreatment);
        switch (type) {
            case 'number':
                tempTreatment[e.target.name] = parseInt(e.target.value);
                break;
            case 'date':
                tempTreatment[e.target.name] = DateTime.fromISO(e.target.value).toISODate();
                break;
            default:
                tempTreatment[e.target.name] = e.target.value;
        }
        setCurrentTreatment(tempTreatment);
    };

    const validatePatientTreatmentUniqueness = (patientId) => {
        if (patientId === undefined || patientId === null) {
            setIsPatientTreatmentUnique(true);
            return true;
        }
        let currentId = currentTreatment.id !== undefined ? currentTreatment.id : 0;
        axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + `treatments/patientTreatmentCount/${currentId}/${patientId}`)
            .then(res => {
                if (res.data.count > 0)
                    setIsPatientTreatmentUnique(false);
                else
                    setIsPatientTreatmentUnique(true);
            });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Grid item container direction='row' justifyContent='space-between' xs={12}>
                        <Grid item xs={1}>
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={handleClose}
                                aria-label='close'
                            >
                                <Close />
                            </IconButton>
                        </Grid>
                        <Grid item container direction='row' xs={11} justifyContent='flex-end' spacing={4} alignItems='center'>
                            <Grid item>
                                <Button
                                    onClick={() => setDeleteOpen(true)}
                                    aria-label='delete'
                                    color='error'
                                    variant='contained'
                                >
                                    <DeleteForever />&nbsp;Kustuta
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleClose}
                                    aria-label='close'
                                    variant='contained'
                                    color='gray'
                                >
                                    <Cancel />&nbsp;Katkesta
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => toast.promise(
                                        handleSave(),
                                        {
                                            pending: 'Salvestan',
                                            success: 'Salvestatud',
                                            error: 'Salvestamisel tekkis tõrge'
                                        }
                                    )}
                                    aria-label='save'
                                    variant='contained'
                                    disabled={isTreatmentInvalid(currentTreatment)}
                                    color='success'
                                >
                                    <Save />&nbsp;Salvesta
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <DialogContent>
                <Grid container justifyContent='center'>
                    <Grid item spacing={6} xs={11} xl={9} container direction='column'>
                        <Grid item>
                            <Typography variant='h4'>
                                {currentTreatment.id ? 'Raviskeem nr ' + currentTreatment.id : 'Uus raviskeem'}
                            </Typography>
                        </Grid>
                        <Grid item container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item xs={6} spacing={1} container direction='row'>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        label='Alguse kuupäev'
                                        mask='__.__.____'
                                        inputFormat='dd.MM.yyyy'
                                        value={currentTreatment.startDate}
                                        onChange={(e) => { modifyCurrentTreatment({ target: { name: 'startDate', value: e } }, 'date'); }}
                                        renderInput={(params) => <TextField required
                                            margin='normal' {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        margin='normal'
                                        name='treatmentLength'
                                        label='Kuuri pikkus (päevades)'
                                        value={currentTreatment.treatmentLength}
                                        onChange={modifyCurrentTreatment}
                                        error={isRequiredFieldInvalid(currentTreatment.treatmentLength)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        disablePortal
                                        options={patients.filter(x => x.isActive)}
                                        getOptionLabel={(patient) => patient.name !== undefined ? patient.id + ' - ' + patient.name + ' - ' + patient.identificationCode : ''}
                                        isOptionEqualToValue={(option, value) => value === undefined ? false : (option.id === value?.id)}
                                        value={patients.filter(x => x.id === currentTreatment.patientId)[0] ?? ''}
                                        onChange={(event, newValue) => {
                                            if (newValue === null)
                                                modifyCurrentTreatment({ target: { name: 'patientId', value: null } });
                                            else
                                                modifyCurrentTreatment({ target: { name: 'patientId', value: newValue.id } });
                                            validatePatientTreatmentUniqueness(newValue?.id);
                                        }}
                                        renderInput={(params) => <TextField
                                            required
                                            error={isRequiredFieldInvalid(currentTreatment.patientId) || !isPatientTreatmentUnique}
                                            helperText={!isPatientTreatmentUnique ? 'Patsiendil juba on raviskeem!' : ''}
                                            margin='normal' {...params} label="Patsient" />}
                                        renderOption={(props, option) => <li {...props}>{option.name + ' - ' + option.identificationCode}</li>}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2} marginTop='20px' justifyContent='flex-end'>
                                <Button
                                    onClick={() => { setSelectedRows([currentTreatment.id]); setSaveFirst(true); setSendOpen(true); }}
                                    aria-label='send'
                                    variant='contained'
                                    disabled={currentTreatment.result === 0}
                                >
                                    <Send />&nbsp;Alusta pakendamist
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
                                <Grid item>
                                    <Typography variant='h6'>
                                        Doosid
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <AddDosageButton
                                        currentTreatment={currentTreatment}
                                        modifyCurrentTreatment={modifyCurrentTreatment}
                                    />
                                </Grid>
                            </Grid>
                            {
                                currentTreatment.treatmentDosages?.map((dosage, i) => {
                                    var modifyDosage = (name, value) => {
                                        var tempDosages = currentTreatment.treatmentDosages;
                                        tempDosages[i][name] = value;
                                        modifyCurrentTreatment({ target: { name: 'treatmentDosages', value: tempDosages } })
                                    };
                                    var modifyWeekdays = (event) => {
                                        var tempWeekdays;
                                        if (event.target.checked) {
                                            if (!dosage.weekdays.includes(event.target.name)) {
                                                tempWeekdays = dosage.weekdays.length > 0 ? dosage.weekdays.split(',') : [];
                                                tempWeekdays.push(event.target.name);
                                                tempWeekdays.sort();
                                                modifyDosage('weekdays', tempWeekdays.join(','));
                                            }
                                        } else {
                                            if (dosage.weekdays.includes(event.target.name)) {
                                                tempWeekdays = dosage.weekdays.split(',');
                                                tempWeekdays.splice(tempWeekdays.indexOf(event.target.name), 1);
                                                modifyDosage('weekdays', tempWeekdays.join(','));
                                            }
                                        }
                                    };

                                    let dosageDrug = drugs.filter(x => x.id === dosage.drugId)[0];
                                    let printTemplate = patients.filter(x => x.id === currentTreatment.patientId)[0]?.institution?.printTemplate ?? 1;
                                    return (
                                        <Grid container direction='row' justifyContent='space-between' key={[dosage.id, dosage.createdAt, i].join('-')}>
                                            <Grid item xs={12}>
                                                <Divider style={{ marginBottom: '15px', marginTop: '15px', backgroundColor: '#A7A7A7' }} />
                                            </Grid>
                                            <Grid container item direction='row' alignItems='center' justifyContent='space-between' xs={12}
                                                style={{
                                                    paddingLeft: '25px'
                                                }}
                                            >
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        disablePortal
                                                        freeSolo
                                                        clearOnBlur
                                                        options={drugs}
                                                        getOptionLabel={(drug) => {
                                                            if (drug.newDrugName)
                                                                return drug.newDrugName;
                                                            return drug.name !== undefined ? drug.id + ' - ' + drug.name : '';
                                                        }}
                                                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        value={drugs.filter(x => x.id === dosage.drugId)[0]}
                                                        onChange={(event, newValue) => {
                                                            if (typeof newValue === 'string') {
                                                                let existingDrugs = drugs.filter(x => x.name === newValue);
                                                                if (existingDrugs.length > 0) {
                                                                    modifyDosage('drugId', existingDrugs[0].id);
                                                                    modifyDosage('newDrug', undefined);
                                                                }
                                                            } else if (newValue && newValue.inputValue) {
                                                                console.log('InputValue');
                                                                console.log(newValue);
                                                            } else {
                                                                if (newValue !== null && newValue.id === undefined) {
                                                                    modifyDosage('newDrug', {
                                                                        id: newValue.newDrugName,
                                                                        name: newValue.newDrugName
                                                                    });
                                                                    modifyDosage('drugId', newValue.newDrugName);
                                                                    let tempDrugs = drugs;
                                                                    tempDrugs.push({
                                                                        id: newValue.newDrugName,
                                                                        name: newValue.newDrugName
                                                                    });
                                                                }
                                                                else {
                                                                    modifyDosage('drugId', newValue?.id ? newValue.id : '');
                                                                    modifyDosage('newDrug', undefined);
                                                                }
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            required
                                                            error={isRequiredFieldInvalid(dosage.drugId)}
                                                            margin='normal' {...params}
                                                            label="Ravim"
                                                        />}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            const { inputValue } = params;
                                                            const isExisting = filtered.some((option) => option.name.includes(inputValue));
                                                            if (inputValue !== '' && !isExisting) {
                                                                filtered.unshift({
                                                                    newDrugName: inputValue,
                                                                    name: `Lisa uus: "${inputValue}"`,
                                                                });
                                                            }

                                                            return filtered;
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <TextField
                                                        required
                                                        margin='normal'
                                                        name='dosage'
                                                        value={dosage.dosage}
                                                        onChange={(e) => modifyDosage('dosage', e.target.value)}
                                                        label='Annus'
                                                        error={!/^\d+(\.\d+)?$/.test(dosage.dosage)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {
                                                        patients.filter(x => x.id === currentTreatment.patientId)[0]?.institution?.printTemplate === 2 &&
                                                        <TextField
                                                            margin='normal'
                                                            name='comments'
                                                            value={dosage.comments}
                                                            onChange={(e) => modifyDosage('comments', e.target.value)}
                                                            label='Kommentaarid'
                                                            fullWidth
                                                        />
                                                    }
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <Button
                                                        onClick={() => {
                                                            var tempDosages = currentTreatment.treatmentDosages;
                                                            tempDosages.splice(i, 1);
                                                            modifyCurrentTreatment({ target: { name: 'treatmentDosages', value: tempDosages } });
                                                        }}
                                                        aria-label='delete'
                                                        color='error'
                                                    >
                                                        <DeleteForever />&nbsp;Kustuta doos
                                                    </Button>
                                                </Grid>
                                                <Grid container direction='row' item xs={12} spacing={1} alignItems='center' justifyContent='space-between'>
                                                    <Grid item>
                                                        {
                                                            ['E', 'T', 'K', 'N', 'R', 'L', 'P'].map((day, j) =>
                                                                <FormControlLabel
                                                                    name={(j + 1).toString()}
                                                                    checked={dosage.weekdays?.includes((j + 1).toString())}
                                                                    onChange={(e) => modifyWeekdays(e)}
                                                                    control={<Checkbox
                                                                        size='small' />}
                                                                    label={day}
                                                                    labelPlacement="top"
                                                                    key={j}
                                                                    style={{
                                                                        marginLeft: '0',
                                                                        marginRight: '0'
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <DosageTimingInputs timing={dosage.timing} modifyDosage={modifyDosage} printTemplate={printTemplate} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {
                                                            printTemplate === 2 &&
                                                            dosageDrug !== undefined &&
                                                            isRequiredFieldInvalid(dosageDrug?.form) &&
                                                            isRequiredFieldInvalid(dosageDrug?.color) &&
                                                            isRequiredFieldInvalid(dosageDrug?.shape) &&
                                                            isRequiredFieldInvalid(dosageDrug?.activeIngredient) &&
                                                            <Grid container direction='row' alignItems='center'>
                                                                <Grid item xs={3}><PriorityHigh fontSize='large' htmlColor='red' /></Grid>
                                                                <Grid item xs={9}><Typography>Ravimil on puudulik lisainfo</Typography></Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <Grid item>
                            <Grid spacing={1} container justifyContent="right">
                                <Grid item xs={2}>
                                    <AddDosageButton
                                        currentTreatment={currentTreatment}
                                        modifyCurrentTreatment={modifyCurrentTreatment}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container direction='column' marginTop='20px'>
                                <Grid item container direction='row' justifyContent='flex-end' spacing={2}>
                                    <Grid item xs={2}>
                                        <Button
                                            onClick={() => setDeleteOpen(true)}
                                            aria-label='delete'
                                            fullWidth
                                            color='error'
                                            variant='contained'
                                        >
                                            <DeleteForever />&nbsp;Kustuta
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            onClick={handleClose}
                                            aria-label='close'
                                            fullWidth
                                            variant='outlined'
                                            color='error'
                                        >
                                            <Cancel />&nbsp;Katkesta
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            onClick={() => toast.promise(
                                                handleSave(),
                                                {
                                                    pending: 'Salvestan',
                                                    success: 'Salvestatud',
                                                    error: 'Salvestamisel tekkis tõrge'
                                                }
                                            )}
                                            aria-label='save'
                                            fullWidth
                                            variant='contained'
                                            disabled={isTreatmentInvalid(currentTreatment)}
                                        >
                                            <Save />&nbsp;Salvesta
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    );
}


function AddDosageButton({ currentTreatment, modifyCurrentTreatment }) {
    return (
        <Button
            onClick={() => {
                var tempDosages = currentTreatment.treatmentDosages ? currentTreatment.treatmentDosages : [];
                tempDosages.push({ drugId: "", dosage: "", timing: "Lõuna", weekdays: "1,2,3,4,5,6,7", createdAt: DateTime.now().toUTC() });
                modifyCurrentTreatment({ target: { name: 'treatmentDosages', value: tempDosages } });
            }}
        >
            <Add />&nbsp;Lisa doos
        </Button>
    );
}

export default TreatmentDialog;