import { Grid } from '@mui/material';
import React from 'react';
import { Oval } from 'react-loader-spinner';

function LoadingOverlay({ display }) {
    return (
        <>
            {
                display &&
                <Grid
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'fixed',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        zIndex: 100000,
                        top: 0
                    }}
                    justifyContent='center'
                    alignItems='center'
                    container
                >
                    <Grid item
                        style={{
                            opacity: 1
                        }}
                    >
                        <Oval
                            height={80}
                            width={80}
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </Grid>
                </Grid>

            }
        </>
    )
}

export default LoadingOverlay;