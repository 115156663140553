import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
    Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    FormControl, FormControlLabel, Grid,
    IconButton, Typography, TablePagination, Switch,
    InputLabel, Select, MenuItem, Checkbox
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import { DateTime } from 'luxon';
import axios from './CustomAxios';
import { UserContext } from './context/UserContext';
import ConfirmationDialog from './Dialogs/ConfirmationDialog';
import printJS from 'print-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TreatmentDialog from './Dialogs/TreatmentDialog';
import PackagingDialog from './Dialogs/PackagingDialog';
import downloadAsFile from './helpers/downloadAsFile';
import SearchInput from './components/SearchInput';
import LoadingLinearProgress from './components/LoadingLinearProgress';


function Treatments() {
    const [userContext, setUserContext] = useContext(UserContext);

    const [currentTreatment, setCurrentTreatment] = useState({});
    const [countAll, setCountAll] = useState(0);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [dense, setDense] = useState(false);
    const [drugs, setDrugs] = useState([]);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [patients, setPatients] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [saveFirst, setSaveFirst] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [sendOpen, setSendOpen] = useState(false);
    const [sortOrderDesc, setSortOrderDesc] = useState(true);
    const [sortOrderField, setSortOrderField] = useState('id');
    const [treatments, setTreatments] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchKeyword = (value => {
        setPage(0);
        setSearchKeyword(value);
    });

    const handleChangeSelectedRows = (e) => {
        let value = parseInt(e.target.value);
        var rows = [];
        rows.push(...selectedRows);
        if (e.target.checked && !rows.includes(value))
            rows.push(parseInt(value));
        else if (!e.target.checked && rows.includes(value))
            rows.splice(rows.indexOf(value), 1);
        setSelectedRows(rows);
    };

    const handleChangeSortOrderField = (event) => {
        setSortOrderField(event.target.value);
    };

    const handleClickOpen = (i) => {
        var tempTreatment = Object.assign({}, treatments[i]);
        for (const property in tempTreatment) {
            if (tempTreatment[property] === null)
                tempTreatment[property] = '';
        }
        setCurrentTreatment(tempTreatment);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreate = () => {
        setCurrentTreatment(
            {
                startDate: DateTime.now(),
                patientId: '',
                treatmentLength: ''
            }
        );
        setOpen(true);
    };

    const handleDelete = () => {
        return axios(userContext.token, setUserContext).delete(process.env.REACT_APP_API_ENDPOINT + 'treatments/' + currentTreatment.id)
            .then(res => {
                getAllTreatments();
                setDeleteOpen(false);
                handleClose();
            });
    };

    const handlePrint = (publicId, saveFirst) => {
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'treatments/print/' + publicId)
            .then(res => {
                var printConfig = {
                    printable: res.data,
                    type: 'raw-html',
                    documentTitle: ''
                };
                if (saveFirst)
                    return handleSave(true).then(() => printJS(printConfig));
                else
                    return printJS(printConfig);
            })
            .catch(err => {
                console.error(err);
                toast.error('Printimisel tekkis viga');
            });
    }

    const handleSave = (keepOpen) => {
        var body = Object.assign({}, currentTreatment);
        delete body.createdAt;
        delete body.updatedAt;
        if (currentTreatment.id) {
            return axios(userContext.token, setUserContext).put(process.env.REACT_APP_API_ENDPOINT + 'treatments/' + currentTreatment.id, body)
                .then(res => {
                    getAllTreatments();
                    getAllDrugs();
                })
                .then(() => {
                    if (!keepOpen)
                        handleClose();
                })
        }
        else {
            return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'treatments', body)
                .then(res => {
                    getAllTreatments();
                    getAllDrugs();
                })
                .then(() => {
                    if (!keepOpen) {
                        handleClose();
                    }
                });
        }
    };

    const handleSend = async (forDownload) => {
        if (saveFirst) {
            await handleSave();
            setSaveFirst(false);
        }
        setSendOpen(false);
        return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'treatments/send', { ids: selectedRows, forDownload: forDownload })
            .then(res => {
                setSelectedRows([]);
                if (forDownload || process.env.REACT_APP_ENVIRONMENT === 'testing') {
                    downloadAsFile('FILIA_RX.xml', res.data);
                }
            });
    };

    const getAllDrugs = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'drugs')
            .then(res => {
                setDrugs(res.data.rows);
            });
    }, [userContext.token, setUserContext]);

    const getAllPatients = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'patients')
            .then(res => {
                setPatients(res.data.rows);
            });
    }, [userContext.token, setUserContext]);

    const getAllTreatments = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        setLoading(true);
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'treatments?param=' + encodeURIComponent(JSON.stringify({
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            order: [
                [sortOrderField, sortOrderDesc ? 'DESC' : 'ASC']
            ],
            searchKeyword: searchKeyword !== '' ? searchKeyword.trim() : undefined
        })))
            .then(res => {
                setTreatments(res.data.rows);
                setCountAll(res.data.count);
                setLoading(false);
            });
    }, [page, rowsPerPage, searchKeyword, sortOrderDesc, sortOrderField, userContext.token, setUserContext]);

    useEffect(() => {
        getAllTreatments();
        getAllPatients();
        getAllDrugs();
    }, [getAllTreatments, getAllPatients, getAllDrugs]);

    return (
        <>
            <ConfirmationDialog
                isDialogOpen={deleteOpen}
                closeDialog={() => setDeleteOpen(false)}
                dialogTitle={'Oled kindel, et soovid KUSTUTADA ' + currentTreatment.patient?.name + ' raviskeemi?'}
                handleAction={() => toast.promise(
                    handleDelete(),
                    {
                        pending: 'Valmistun kustutama',
                        success: 'Kustutatud',
                        error: 'Kustutamisel tekkis tõrge'
                    }
                )}
            />
            <PackagingDialog
                isDialogOpen={sendOpen}
                closeDialog={() => { setSelectedRows([]); setSendOpen(false); }}
                dialogTitle={selectedRows.length > 1 ? 'Oled kindel, et soovid PAKENDADA raviskeeme ' + selectedRows.join(', ') + '?'
                    : (selectedRows.length === 1 ? 'Oled kindel, et soovid PAKENDADA ' + treatments.filter(x => x.id === selectedRows[0])[0]?.patient?.name + ' raviskeemi?' : '')}
                handleSendAction={() => toast.promise(
                    handleSend(false),
                    {
                        pending: 'Valmistun pakendama',
                        success: 'Pakendamine alustatud',
                        error: 'Eelmine pakendamine pole veel lõppenud'
                    }
                )}
                handleDownloadAction={() => toast.promise(
                    handleSend(true),
                    {
                        pending: 'Valmistun faili alla laadima',
                        success: 'Faili loomine õnnestus',
                        error: 'Faili loomine ebaõnnestus'
                    }
                )}
            />

            <TreatmentDialog
                handleClose={handleClose}
                currentTreatment={currentTreatment}
                setCurrentTreatment={setCurrentTreatment}
                patients={patients}
                drugs={drugs}
                setSelectedRows={setSelectedRows}
                setSaveFirst={setSaveFirst}
                setSendOpen={setSendOpen}
                setDeleteOpen={setDeleteOpen}
                handleSave={handleSave}
                open={open}
            />
            <LoadingLinearProgress loading={loading} />
            <Grid
                container
                className='treatment-filter-bar'
                spacing={4}
                style={{ marginTop: 0, marginBottom: '10px' }}
            >
                <Grid alignContent='center' item xs={2}>
                    <Typography variant='h4'>Raviskeemid</Typography>
                </Grid>
                <Grid alignContent='center' item container xs={0.5}>
                    {
                        sortOrderDesc ?
                            <IconButton
                                onClick={() => setSortOrderDesc(!sortOrderDesc)}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                            :
                            <IconButton
                                onClick={() => setSortOrderDesc(!sortOrderDesc)}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                    }
                </Grid>
                <Grid container item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel>Sorteeritav väli</InputLabel>
                        <Select
                            value={sortOrderField}
                            label='Sorteeritav väli'
                            onChange={handleChangeSortOrderField}
                        >
                            <MenuItem value='id'>ID</MenuItem>
                            <MenuItem value='createdAt'>Loodud</MenuItem>
                            <MenuItem value='updatedAt'>Muudetud</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <SearchInput event={handleChangeSearchKeyword} />
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                        style={{ height: '100%' }}
                        control={
                            <Switch checked={dense} onChange={handleChangeDense} />
                        }
                        label='Kitsas vaade'
                    />
                </Grid>
                <Grid container item xs={1.5} justifyContent='flex-end'>
                    <Button onClick={handleCreate}><AddIcon />&nbsp;Lisa uus</Button>
                </Grid>
                <Grid container item xs={1} alignItems='center'>
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={() => { setSendOpen(true); }}
                        aria-label='save'
                        className='treatment-action-button'
                        disabled={selectedRows.length === 0}
                    >
                        <SendIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size={dense ? 'small' : 'medium'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell align='left'>Patsiendi nimi</TableCell>
                            <TableCell align='left'>Asutus</TableCell>
                            <TableCell align='left'>Algus</TableCell>
                            <TableCell align='left'>Pikkus</TableCell>
                            <TableCell align='right'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            treatments.map((treatment, i) => (
                                <TableRow
                                    key={treatment.id}
                                    className='tableRow'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell><Checkbox checked={selectedRows.includes(treatment.id)} value={treatment.id} onChange={(e) => handleChangeSelectedRows(e)}></Checkbox></TableCell>
                                    <TableCell component='th' scope='row'>{treatment.id}</TableCell>
                                    <TableCell align='left'>{treatment.patient?.name}</TableCell>
                                    <TableCell align='left'>{treatment.patient?.institution?.name}</TableCell>
                                    <TableCell align='left'>{DateTime.fromISO(treatment.startDate).toFormat('dd.MM.yyyy')}</TableCell>
                                    <TableCell align='left'>{treatment.treatmentLength}</TableCell>
                                    <TableCell align='center'>
                                        <Grid container justifyContent='space-around'>
                                            <Grid item>
                                                <IconButton
                                                    edge='start'
                                                    color='inherit'
                                                    onClick={() => handleClickOpen(i)}
                                                    aria-label='open'
                                                    className='treatment-action-button'
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    edge='start'
                                                    color='inherit'
                                                    onClick={() => { setSelectedRows([treatment.id]); setSendOpen(true); }}
                                                    aria-label='save'
                                                    className='treatment-action-button'
                                                    disabled={treatment.result === 0}
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    edge='start'
                                                    color='inherit'
                                                    onClick={() => handlePrint(treatment.id)}
                                                    aria-label='print'
                                                    className='test-action-button'
                                                >
                                                    <PrintIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={countAll}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}


export default Treatments;