import React, { useContext, useState } from 'react';
import {
    Grid, Button,
    TextField, Typography
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { UserContext } from '../context/UserContext';
import axios from '../CustomAxios';
import { DateTime } from 'luxon';
import downloadAsFile from '../helpers/downloadAsFile';

function DrugReport() {
    const [userContext, setUserContext] = useContext(UserContext);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleGenerateReport = () => {
        return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'reports/drugs',
            {
                startDate: startDate,
                endDate: endDate.plus({ day: 1 })
            },
            {
                responseType: 'blob'
            }
        )
            .then(res => {
                downloadAsFile('saaris-ravimid.xlsx', res.data);
            });
    };

    return (
        <>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item style={{ paddingBottom: '20px' }}>
                    <Typography variant='h5'>Ravimite raport</Typography>
                </Grid>
                <Grid item>
                    <DesktopDatePicker
                        label='Pakendamise algus'
                        mask='__.__.____'
                        inputFormat='dd.MM.yyyy'
                        value={startDate}
                        onChange={(e) => { setStartDate(e); }}
                        renderInput={(params) => <TextField required
                            margin='normal' {...params} />}
                    />
                </Grid>
                <Grid item>
                    <DesktopDatePicker
                        label='Pakendamise lõpp'
                        mask='__.__.____'
                        inputFormat='dd.MM.yyyy'
                        value={endDate}
                        onChange={(e) => { setEndDate(e); }}
                        renderInput={(params) => <TextField required
                            margin='normal' {...params}
                            helperText={DateTime.fromISO(endDate) < DateTime.fromISO(startDate) ? 'Perioodi lõpp ei saa olla enne algust' : ''}
                            {...params}
                            error={DateTime.fromISO(endDate) < DateTime.fromISO(startDate)}
                        />
                        }
                    />
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => handleGenerateReport()}
                        disabled={
                            startDate === null || endDate === null ||
                            DateTime.fromISO(endDate) < DateTime.fromISO(startDate)
                        }
                    >
                        Loo raport
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default DrugReport;