import React, { useState } from 'react';
import {
    Grid, Button
} from '@mui/material';
import PatientReport from './Reports/PatientReport';
import TreatmentReport from './Reports/TreatmentReport';
import DrugReport from './Reports/DrugReport';
import RetailAuditReport from './Reports/RetailAuditReport';

function Reporting() {
    const [selectedReport, setSelectedReport] = useState('');

    return (
        <>
            <Grid container justifyContent='center' direction='row' alignItems='center' style={{ paddingTop: '20px', paddingBottom: '20px' }} spacing={2}>
                <Grid item>
                    <Button variant='outlined' onClick={() => setSelectedReport('patient')}>Patsiendi raport</Button>
                </Grid>
                <Grid item>
                    <Button variant='outlined' onClick={() => setSelectedReport('treatment')}>Skeemide raport</Button>
                </Grid>
                <Grid item>
                    <Button variant='outlined' onClick={() => setSelectedReport('drug')}>Ravimite raport</Button>
                </Grid>
                <Grid item>
                    <Button variant='outlined' onClick={() => setSelectedReport('retailAudit')}>Jaendamise raport</Button>
                </Grid>
            </Grid>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
                {
                    selectedReport === 'patient' &&
                    <PatientReport />
                }
                {
                    selectedReport === 'treatment' &&
                    <TreatmentReport />
                }
                {
                    selectedReport === 'drug' &&
                    <DrugReport />
                }
                {
                    selectedReport === 'retailAudit' &&
                    <RetailAuditReport />
                }
            </Grid>
        </>
    );
}

export default Reporting;