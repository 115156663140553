import React from 'react';
import {
    Button, Dialog, DialogTitle, DialogActions
} from '@mui/material';

function ConfirmationDialog({ isDialogOpen, closeDialog, dialogTitle, handleAction }) {
    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
        >
            <DialogTitle>
                {dialogTitle}
            </DialogTitle>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={closeDialog}
                >Ei</Button>
                <Button
                    onClick={handleAction}
                >Jah</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;