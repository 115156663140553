import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
    Button, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Dialog, DialogTitle, DialogContent, DialogActions, FormControl,
    FormControlLabel, Grid, AppBar, Toolbar, IconButton, Typography, TablePagination, Switch,
    InputLabel, Select, MenuItem, Autocomplete, Stack
} from '@mui/material';
import { Add, ArrowDownward, ArrowUpward, Cancel, Close, DeleteForever, Edit, Save } from '@mui/icons-material';
import { DateTime } from 'luxon';
import axios from './CustomAxios';
import { UserContext } from './context/UserContext';
import { toast } from 'react-toastify';
import validateIdCode from './helpers/validateIdCode';
import SearchInput from './components/SearchInput';
import LoadingLinearProgress from './components/LoadingLinearProgress';

function Patients() {
    const [userContext, setUserContext] = useContext(UserContext)

    const [currentPatient, setCurrentpatient] = useState({});
    const [currentIdCodeUnique, setCurrentIdCodeUnique] = useState(true);
    const [sortOrderDesc, setSortOrderDesc] = useState(true);
    const [sortOrderField, setSortOrderField] = useState('id');
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [countAll, setCountAll] = useState(0);
    const [dense, setDense] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);

    const [patients, setPatients] = useState([]);
    const [institutions, setInstitutions] = useState([]);

    const handleClickOpen = (i) => {
        var temppatient = Object.assign({}, patients[i]);
        for (const property in temppatient) {
            if (temppatient[property] === null)
                temppatient[property] = '';
        }
        setCurrentpatient(temppatient);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleSave = (keepOpen) => {
        var body = Object.assign({}, currentPatient);
        delete body.createdAt;
        delete body.updatedAt;
        if (body.identificationCode)
            if (currentPatient.id) {
                return axios(userContext.token, setUserContext).put(process.env.REACT_APP_API_ENDPOINT + 'patients/' + currentPatient.id, body)
                    .then(res => {
                        getAllPatients();
                    })
                    .then(() => {
                        if (!keepOpen)
                            setOpen(false);
                    });
            }
            else {
                return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'patients', body)
                    .then(res => {
                        getAllPatients();
                    })
                    .then(() => {
                        if (!keepOpen) {
                            setOpen(false);
                        }
                    });
            }
    };

    const handleDelete = () => {
        return axios(userContext.token, setUserContext).delete(process.env.REACT_APP_API_ENDPOINT + 'patients/' + currentPatient.id)
            .then(res => {
                getAllPatients();
                setDeleteOpen(false);
                setOpen(false);
            });
    };

    const handleChangeSearchKeyword = (value => {
        setPage(0);
        setSearchKeyword(value);
    });

    const handleCreate = () => {
        setCurrentpatient(
            {
            }
        );
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSortOrderField = (event) => {
        setSortOrderField(event.target.value);
    };

    const modifyCurrentPatient = (e, isNumber, valueProperty = 'value') => {
        var temppatient = Object.assign({}, currentPatient);

        // Always modify name into uppercase
        if (e.target.name === 'name')
            e.target[valueProperty] = e.target[valueProperty].toUpperCase();

        if (isNumber)
            temppatient[e.target.name] = parseInt(e.target[valueProperty]);
        else
            temppatient[e.target.name] = e.target[valueProperty];
        setCurrentpatient(temppatient);
    };

    const getAllInstitutions = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'institutions')
            .then(res => {
                setInstitutions(res.data.rows);
            });
    }, [userContext.token, setUserContext]);

    const getAllPatients = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        setLoading(true);
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'patients?param=' + encodeURIComponent(JSON.stringify({
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            order: [
                [sortOrderField, sortOrderDesc ? 'DESC' : 'ASC']
            ],
            searchKeyword: searchKeyword !== '' ? searchKeyword.trim() : undefined
        })))
            .then(res => {
                setPatients(res.data.rows);
                setCountAll(res.data.count);
                setLoading(false);
            });
    }, [page, rowsPerPage, searchKeyword, sortOrderDesc, sortOrderField, userContext.token, setUserContext]);

    const validateIdCodeUniqueness = (idCode) => {
        if (idCode === undefined || !validateIdCode(idCode)) {
            setCurrentIdCodeUnique(true);
            return true;
        }
        let currentId = currentPatient.id !== undefined ? currentPatient.id : 0;
        axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + `patients/validateIdCode/${currentId}/${idCode}`)
            .then(res => {
                if (res.data.count > 0)
                    setCurrentIdCodeUnique(false);
                else
                    setCurrentIdCodeUnique(true);
            });
    }

    useEffect(() => {
        getAllPatients();
        getAllInstitutions();
    }, [getAllPatients, getAllInstitutions]);

    return (
        <>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
            >
                <DialogTitle>
                    Oled kindel, et soovid kustutada patsienti {currentPatient.id}?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>Ei</Button>
                    <Button
                        autoFocus
                        onClick={() => toast.promise(
                            handleDelete(),
                            {
                                pending: 'Valmistun kustutama',
                                success: 'Kustutatud',
                                error: 'Kustutamisel tekkis tõrge'
                            }
                        )}
                    >Jah</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid item container direction='row' justifyContent='space-between' xs={12}>
                            <Grid item xs={1}>
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={handleClose}
                                    aria-label='close'
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                            <Grid item container direction='row' xs={11} justifyContent='flex-end' spacing={4} alignItems='center'>
                                <Grid item>
                                    <Button
                                        onClick={() => setDeleteOpen(true)}
                                        aria-label='delete'
                                        color='error'
                                        variant='contained'
                                    >
                                        <DeleteForever />&nbsp;Kustuta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleClose}
                                        aria-label='close'
                                        variant='contained'
                                        color='gray'
                                    >
                                        <Cancel />&nbsp;Katkesta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => toast.promise(
                                            handleSave(),
                                            {
                                                pending: 'Salvestan',
                                                success: 'Salvestatud',
                                                error: 'Salvestamisel tekkis tõrge'
                                            }
                                        )}
                                        aria-label='save'
                                        variant='contained'
                                        disabled={
                                            currentPatient.name === undefined ||
                                            currentPatient.name === '' ||
                                            currentPatient.identificationCode === undefined ||
                                            currentPatient.identificationCode === '' ||
                                            currentPatient.institutionId === null ||
                                            currentPatient.institutionId === undefined ||
                                            !currentIdCodeUnique
                                        }
                                        color='success'
                                    >
                                        <Save />&nbsp;Salvesta
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogTitle>
                    {currentPatient.id ? 'Patsient nr ' + currentPatient.id : 'Uus patsient'}
                </DialogTitle>
                <DialogContent>
                    <Grid spacing={8} container direction='row'>
                        <Grid item xs={6}>
                            <TextField
                                margin='normal'
                                name='identificationCode'
                                value={currentPatient.identificationCode}
                                error={
                                    !validateIdCode(currentPatient.identificationCode) ||
                                    !currentIdCodeUnique
                                }
                                helperText={
                                    !validateIdCode(currentPatient.identificationCode) ?
                                        'Isikukood ei ole korrektne' :
                                        !currentIdCodeUnique ?
                                            'Isikukood ei ole unikaalne!' :
                                            ''
                                }
                                onChange={(e) => {
                                    modifyCurrentPatient(e);
                                    validateIdCodeUniqueness(e.target.value);
                                }}
                                label='Isikukood'
                                fullWidth
                                required
                            />
                            <TextField
                                margin='normal'
                                name='name'
                                value={currentPatient.name}
                                onChange={modifyCurrentPatient}
                                label='Nimi'
                                fullWidth
                                required
                            />
                            <Autocomplete
                                disablePortal
                                required
                                options={institutions}
                                getOptionLabel={(institution) => institution.name}
                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                value={institutions.filter(x => x.id === currentPatient.institutionId)[0]}
                                onChange={(event, newValue) => {
                                    if (newValue === null)
                                        modifyCurrentPatient({ target: { name: 'institutionId', value: null } });
                                    else
                                        modifyCurrentPatient({ target: { name: 'institutionId', value: newValue.id } })
                                }}
                                renderInput={(params) => <TextField
                                    margin='normal' {...params} label="Asutus" required />}
                            />
                            <TextField
                                margin='normal'
                                name='familyPhysician'
                                value={currentPatient.familyPhysician}
                                onChange={modifyCurrentPatient}
                                label='Perearst'
                                fullWidth
                            />
                            <TextField
                                margin='normal'
                                name='familyPhysicianContact'
                                value={currentPatient.familyPhysicianContact}
                                onChange={modifyCurrentPatient}
                                label='Perearsti kontakt'
                                fullWidth
                            />
                            <Stack direction='row' spacing={1} alignItems='center' style={{ paddingBottom: '20px' }}>
                                <Typography>Mitte aktiivne</Typography>
                                <Switch
                                    checked={currentPatient.isActive ?? true}
                                    name='isActive'
                                    onChange={(e) => modifyCurrentPatient(e, false, 'checked')}
                                />
                                <Typography>Aktiivne</Typography>
                            </Stack>
                            {
                                currentPatient.createdAt && currentPatient.updatedAt &&
                                <Grid item>
                                    <Typography>Loodud: {DateTime.fromISO(currentPatient.createdAt).toFormat('dd.MM.yyyy HH:mm')}</Typography>
                                    <Typography>Muudetud: {DateTime.fromISO(currentPatient.updatedAt).toFormat('dd.MM.yyyy HH:mm')}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <LoadingLinearProgress loading={loading} />
            <Grid
                container
                className='patient-filter-bar'
                spacing={4}
                style={{ marginTop: 0, marginBottom: '10px' }}
            >
                <Grid alignContent='center' item xs={2}>
                    <Typography variant='h4'>Patsiendid</Typography>
                </Grid>
                <Grid item container xs={0.5}>
                    {
                        sortOrderDesc ?
                            <IconButton
                                onClick={() => setSortOrderDesc(!sortOrderDesc)}
                            >
                                <ArrowDownward />
                            </IconButton>
                            :
                            <IconButton
                                onClick={() => setSortOrderDesc(!sortOrderDesc)}
                            >
                                <ArrowUpward />
                            </IconButton>
                    }
                </Grid>
                <Grid container item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel>Sorteeritav väli</InputLabel>
                        <Select
                            value={sortOrderField}
                            label='Sorteeritav väli'
                            onChange={handleChangeSortOrderField}
                        >
                            <MenuItem value='id'>ID</MenuItem>
                            <MenuItem value='createdAt'>Loodud</MenuItem>
                            <MenuItem value='updatedAt'>Muudetud</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <SearchInput event={handleChangeSearchKeyword} />
                </Grid>
                <Grid item xs={1.5}>
                    <FormControlLabel
                        style={{ height: '100%' }}
                        control={
                            <Switch checked={dense} onChange={handleChangeDense} />
                        }
                        label='Kitsas vaade'
                    />
                </Grid>
                <Grid container item xs={1.5} justifyContent='flex-end'>
                    <Button onClick={handleCreate}><Add />&nbsp;Lisa uus</Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size={dense ? 'small' : 'medium'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align='left'>Nimi</TableCell>
                            <TableCell align='left'>Isikukood</TableCell>
                            <TableCell align='left'>Asutus</TableCell>
                            <TableCell align='left'>Aktiivne</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            patients.map((patient, i) => (
                                <TableRow
                                    key={patient.id}
                                    className='tableRow'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component='th' scope='row'>{patient.id}</TableCell>
                                    <TableCell align='left'>{patient.name}</TableCell>
                                    <TableCell align='left'>{patient.identificationCode}</TableCell>
                                    <TableCell align='left'>{patient.institution?.name}</TableCell>
                                    <TableCell align='left'>{patient.isActive ? 'Jah' : 'Ei'}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton
                                            edge='start'
                                            color='inherit'
                                            onClick={() => handleClickOpen(i)}
                                            aria-label='open'
                                            className='patient-action-button'
                                        >
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={countAll}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default Patients;