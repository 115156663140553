import React, { useContext, useState, useCallback, useEffect } from 'react';
import {
    Grid, Button,
    Autocomplete, TextField, Typography
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { UserContext } from '../context/UserContext';
import axios from '../CustomAxios';
import printJS from 'print-js';
import { DateTime } from 'luxon';

function PatientReport() {
    const [userContext, setUserContext] = useContext(UserContext);

    const [patients, setPatients] = useState([]);
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleGenerateReport = () => {
        return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'reports/patient', {
            patientId: selectedPatientId,
            startDate: startDate,
            endDate: endDate.plus({day: 1})
        })
            .then(res => {
                var printConfig = {
                    printable: res.data,
                    type: 'raw-html',
                    documentTitle: ''
                };
                return printJS(printConfig);
            });
    };

    const getAllPatients = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'patients')
            .then(res => {
                setPatients(res.data.rows);
            });
    }, [userContext.token, setUserContext]);

    useEffect(() => {
        getAllPatients();
    }, [getAllPatients]);

    return (
        <>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item style={{ paddingBottom: '20px' }}>
                    <Typography variant='h5'>Patsiendi raport</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        options={patients}
                        getOptionLabel={(patient) => patient.name !== undefined ? patient.id + ' - ' + patient.name + ' - ' + patient.identificationCode : ''}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        value={patients.filter(x => x.id === selectedPatientId)[0] ?? null}
                        sx={{ 'minWidth': 300 }}
                        onChange={(event, newValue) => {
                            if (newValue === null)
                                setSelectedPatientId(null);
                            else
                                setSelectedPatientId(newValue.id);
                        }}
                        renderInput={(params) => <TextField
                            required
                            margin='normal' {...params} label="Patsient" />}
                    />
                </Grid>
                <Grid item>
                    <DesktopDatePicker
                        label='Pakendamise algus'
                        mask='__.__.____'
                        inputFormat='dd.MM.yyyy'
                        value={startDate}
                        onChange={(e) => { setStartDate(e); }}
                        renderInput={(params) => <TextField required
                            margin='normal' {...params} />}
                    />
                </Grid>
                <Grid item>
                    <DesktopDatePicker
                        label='Pakendamise lõpp'
                        mask='__.__.____'
                        inputFormat='dd.MM.yyyy'
                        value={endDate}
                        onChange={(e) => { setEndDate(e); }}
                        renderInput={(params) => <TextField required
                            margin='normal' {...params}
                            helperText={DateTime.fromISO(endDate) < DateTime.fromISO(startDate) ? 'Perioodi lõpp ei saa olla enne algust' : ''}
                            {...params}
                            error={DateTime.fromISO(endDate) < DateTime.fromISO(startDate)}
                        />
                        }
                    />
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => handleGenerateReport()}
                        disabled={
                            selectedPatientId === null || startDate === null || endDate === null ||
                            DateTime.fromISO(endDate) < DateTime.fromISO(startDate)
                        }
                    >
                        Loo raport
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default PatientReport;